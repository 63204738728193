import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export const infoSlider = () => {
  // スライダーの要素を取得
  const infoSliderElement = document.querySelector('.js-infoSlider');
  const slideCount = infoSliderElement.querySelectorAll('.splide__slide').length;
  
  // スライダーの要素が存在する場合のみSplideを初期化
  if (infoSliderElement) {
    // Splideのインスタンスを作成
    const infoSliderSetting = new Splide(infoSliderElement, {
      type: slideCount <= 2 ? 'slide' : 'loop',
      arrows: slideCount <= 2 ? false : true,
      pagination: false,
      gap: '50px',
      perMove: 1,
      perPage: 2,
      breakpoints: {
        992: {
          gap: '20px',
        },
        768: {
          type: slideCount <= 1 ? 'slide' : 'loop',
          arrows: slideCount <= 1 ? false : true,
          perPage: 1,
          focus: slideCount <= 1 ? 'left' : 'center',
        },
      },
    });
      
    // リサイズ時にSplideをリフレッシュ
    window.addEventListener('resize', () => {
      infoSliderSetting.destroy().mount();
    });

    // Splideをマウント
    infoSliderSetting.mount();
  }
}
