import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export const achievementsSlider = () => {
  // スライダーの要素を取得
  const achievementSliderElement = document.querySelector('.js-achievementsSlider');
  const slideCount = achievementSliderElement.querySelectorAll('.splide__slide').length;

  // スライダーの要素が存在する場合のみSplideを初期化
  if (achievementSliderElement) {
    const achievementSliderSetting = new Splide(achievementSliderElement, {
      type: slideCount <= 3 ? 'slide' : 'loop',
      arrows: slideCount <= 3 ? false : true,
      pagination: false,
      gap: '50px',
      perPage: 3,
      perMove: 1,
      breakpoints: {
        1024: {
          type: slideCount <= 2 ? 'slide' : 'loop',
          arrows: slideCount <= 2 ? false : true,
          perPage: 2,
          focus: 'left',
          gap: '30px',
        },
        768: {
          type: slideCount <= 1 ? 'slide' : 'loop',
          arrows: slideCount <= 1 ? false : true,
          perPage: 1,
          focus: slideCount <= 1 ? 'left' : 'center',
          gap: '20px',
        },
      },
    });


    // リサイズ時にSplideをリフレッシュ
    window.addEventListener('resize', () => {
      achievementSliderSetting.destroy().mount();
    });

    // Splideをマウント
    achievementSliderSetting.mount();
  }
}
